import React, { useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  IconButton,
  Toolbar,
  Hidden,
  Typography
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';

import { Emergency } from 'common/components';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFFFFF'
  },
  textColor: {
    color: '#FFFFFF'
  },
  icon: {
    color: '#37474F',
    fontSize: '30px'
  }
}));

type Props = {
  className: string;
  title: string;
  onOpenNavBarMobile: () => void;
};

const TopBar: React.FC<Props> = ({ onOpenNavBarMobile, className, title }) => {
  const classes = useStyles();

  /** Dialog */
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onOpenNavBarMobile}>
              <Menu className={classes.icon} />
            </IconButton>
          </Hidden>
        </div>
        <div style={{ flexGrow: 2 }}>
          <Typography variant="h1">{title}</Typography>
        </div>
        <Hidden lgUp>
          <div onClick={handleClickOpen}>
            <img
              src="/images/goal/emergency.svg"
              alt=""
              style={{ marginRight: '12px', cursor: 'pointer' }}
            />
          </div>
        </Hidden>
      </Toolbar>
      {open && <Emergency open={open} close={handleClose} />}
    </AppBar>
  );
};

export default TopBar;
