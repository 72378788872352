import React from 'react';

import { createStyles, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';

const baseStyle = {
  width: '100%',
  height: '50px',
  borderRadius: '25px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontSize: '18px',
  lineHeight: '21px',
  cursor: 'pointer'
};

const baseAuthStyle = {
  width: '100%',
  height: '50px',
  borderRadius: '25px',
  fontFamily: 'Scada',
  fontStyle: 'normal',
  fontSize: '24px',
  lineHeight: '30px',
  cursor: 'pointer'
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = () =>
  createStyles({
    primary: {
      ...baseStyle,
      boxSizing: 'border-box',
      fontWeight: 'bold',
      border: 'none',
      color: '#FFFFFF',
      backgroundColor: '#1F7CE2',
      '&:focus': {
        outline: 'none'
      },
      '&:hover': {
        backgroundColor: '#1F7CE2'
      },
      '&:active': {
        backgroundColor: '#1F7CE2'
      }
    },
    secondary: {
      ...baseStyle,
      boxSizing: 'border-box',
      fontWeight: 'bold',
      border: '1.5px solid #75B7FF',
      color: '#75B7FF',
      backgroundColor: '#FFFFFF',
      '&:focus': {
        outline: 'none'
      },
      '&:hover': {
        backgroundColor: '#FFFFFF'
      },
      '&:active': {
        backgroundColor: '#FFFFFF'
      }
    },
    tertiary: {
      ...baseStyle,
      boxSizing: 'border-box',
      fontWeight: 'bold',
      border: '1.5px solid #75B7FF',
      color: '#75B7FF',
      backgroundColor: '#FFFFFF',
      '&:focus': {
        outline: 'none'
      },
      '&:hover': {
        backgroundColor: '#EEEEEE'
      },
      '&:active': {
        backgroundColor: '#FFFFFF'
      }
    },
    cancelLoader: {
      ...baseStyle,
      boxSizing: 'border-box',
      fontWeight: 'bold',
      border: '1.5px solid #FFFFFF',
      color: '#FFFFFF',
      backgroundColor: '#75B7FF',
      '&:focus': {
        outline: 'none'
      },
      '&:hover': {
        backgroundColor: '#75B7FF'
      },
      '&:active': {
        backgroundColor: '#75B7FF'
      }
    },
    important: {
      ...baseStyle,
      boxSizing: 'border-box',
      fontWeight: 'bold',
      border: 'None',
      color: '#FFFFFF',
      backgroundColor: 'rgba(236, 28, 12, 0.72)',
      '&:focus': {
        outline: 'none'
      },
      '&:hover': {
        backgroundColor: 'rgba(236, 28, 12, 0.72)'
      },
      '&:active': {
        backgroundColor: 'rgba(236, 28, 12, 0.72)'
      }
    },
    consumer: {
      ...baseAuthStyle,
      border: '2px solid #FFFFFF',
      color: '#FFFFFF',
      backgroundColor: '#73BA9B',
      '&:focus': {
        outline: 'none'
      },
      '&:hover': {
        backgroundColor: '#73BA9B'
      },
      '&:active': {
        backgroundColor: '#73BA9B'
      }
    },
    carer: {
      ...baseAuthStyle,
      border: '2px solid #FFFFFF',
      color: '#FFFFFF',
      backgroundColor: '#85BFFF',
      '&:focus': {
        outline: 'none'
      },
      '&:hover': {
        backgroundColor: '#85BFFF'
      },
      '&:active': {
        backgroundColor: '#85BFFF'
      }
    }
  });

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode;
  click?: () => void;
  type: string;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({
  children,
  click,
  type,
  disabled,
  classes
}: Props) => {
  let classType = '';
  if (type === 'primary') {
    classType = classes.primary;
  } else if (type === 'secondary') {
    classType = classes.secondary;
  } else if (type === 'tertiary') {
    classType = classes.tertiary;
  } else if (type === 'important') {
    classType = classes.important;
  } else if (type === 'consumer') {
    classType = classes.consumer;
  } else if (type === 'carer') {
    classType = classes.carer;
  } else if (type === 'cancelLoader') {
    classType = classes.cancelLoader;
  }
  return disabled ? (
    <button className={classType}>{children}</button>
  ) : (
    <button className={classType} onClick={click}>
      {children}
    </button>
  );
};

export default withStyles(styles)(Button);
