import { createSlice } from '@reduxjs/toolkit';
import { AuthRootType } from 'types/auth';

const initialState: AuthRootType = {
  gender: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    start() {},
    end() {}
  }
});

export const { start, end } = authSlice.actions;
export default authSlice.reducer;
