import React, { useState, Suspense, useEffect } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import useRouter from 'common/utils/useRouter';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  LinearProgress,
  Theme,
  Hidden,
  Dialog,
  DialogContent,
  useMediaQuery
} from '@material-ui/core';
import clsx from 'clsx';

import {
  MyPeopleTopBar,
  GoalTopBar,
  JourneyTopBar,
  NetworkTopBar,
  SafetyTopBar,
  StoryTopBar,
  ProfileTopBar,
  DashboardTopBar,
  DefaultTopBar,
  AboutTopBar
} from './Topbars';
import { NavBar } from '../components';
import { LogoutPopup } from 'common/components';
import { callProfileReadApi } from 'slices/auth/action';
import { ClimbingBoxLoader } from 'react-spinners';
import { css } from '@emotion/core';

const override = css`
  display: block;
  margin: 0 auto;
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    overflow: 'hidden'
  },
  mainContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 150,
    minWidth: 150,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    backgroundColor: '#FFFFFF'
  },
  contentWithBackground: {
    overflowY: 'auto',
    flex: '1 1 auto',
    backgroundColor: '#DEE9FE'
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
    paddingTop: '20px'
  },
  storyTopBar: {
    zIndex: 2,
    position: 'absolute',
    top: '0',
    paddingTop: '20px',
    background: 'rgba(0, 0, 0, 0.5)'
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#DEE9FE'
  }
}));

const BaseLayoutWithNavbar: React.FC<RouteConfigComponentProps> = ({
  route
}) => {
  const classes = useStyles();
  const { history } = useRouter();

  //Hammad Tahir - switchAccountFix - 190721 - Start
  /** Theme */
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('xs'));

  const [profileRead, setProfileRead] = useState(false);

  const loginCheck = async () => {
    if (sessionStorage.getItem('LoggedInAs') === null) {
      await callProfileReadApi().then(() => setProfileRead(true));
    }
    if (sessionStorage.getItem('LoggedInAs') !== 'RelatedPerson') {
      history.replace('/errors/error-403');
    }
  };
  //Hammad Tahir - switchAccountFix - 190721 - end

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  let topBar = null;
  let menu = '';
  if (history.location.pathname === '/mypeople') {
    menu = 'mypeople';
    topBar = (
      <Hidden lgUp>
        <MyPeopleTopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
      </Hidden>
    );
  } else if (
    history.location.pathname === '/goals/current' ||
    history.location.pathname === '/goals/completed'
  ) {
    topBar = (
      <Hidden lgUp>
        <GoalTopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
      </Hidden>
    );
  } else if (history.location.pathname.startsWith('/journeys/')) {
    topBar = (
      <Hidden lgUp>
        <JourneyTopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
      </Hidden>
    );
  } else if (
    history.location.pathname === '/networks/people' ||
    history.location.pathname === '/networks/services'
  ) {
    topBar = (
      <Hidden lgUp>
        <NetworkTopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
      </Hidden>
    );
  } else if (history.location.pathname === '/notification') {
    menu = 'notification';
    topBar = (
      <DefaultTopBar
        title="Notifications"
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
    );
  } else if (history.location.pathname === '/settings') {
    menu = 'settings';
    topBar = (
      <DefaultTopBar
        title="Settings"
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
    );
  } else if (history.location.pathname === '/safety') {
    topBar = (
      <SafetyTopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
    );
  } else if (history.location.pathname === '/story') {
    topBar = (
      <Hidden lgUp>
        <StoryTopBar
          className={classes.storyTopBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
      </Hidden>
    );
  } else if (history.location.pathname === `/profile`) {
    topBar = (
      <ProfileTopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
    );
  } else if (history.location.pathname === '/dashboard') {
    topBar = (
      <Hidden lgUp>
        <DashboardTopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
      </Hidden>
    );
    //Smarth Bajaj Changes
  } else if (history.location.pathname === '/about') {
    menu = 'about';
    topBar = (
      <AboutTopBar
        title="About"
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
    );
  }

  //Hammad Tahir - switchAccountFix - 190721 - Start
  useEffect(() => {
    loginCheck();
  }, [profileRead]);
  //Hammad Tahir - switchAccountFix - 190721 - End
  return (
    <>
      {/* Hammad Tahir - switchAccountFix - 190721 - Show climber box while reading profile */}
      {sessionStorage.getItem('LoggedInAs') === null ? (
        <main className={classes.content}>
          <Dialog open keepMounted fullScreen={fullScreen}>
            <DialogContent className={classes.loadingContainer}>
              <ClimbingBoxLoader
                css={override}
                size={20}
                color={'#337ab7'}
                loading
              />
            </DialogContent>
          </Dialog>
        </main>
      ) : (
        <div className={classes.root}>
          <NavBar
            className={classes.navBar}
            onMobileClose={handleNavBarMobileClose}
            openMobile={openNavBarMobile}
          />
          <div className={classes.mainContainer}>
            {topBar}
            <div className={classes.container}>
              <main
                className={clsx(
                  classes.content,
                  menu !== '' && classes.contentWithBackground
                )}>
                <Suspense fallback={<LinearProgress />}>
                  {route ? renderRoutes(route.routes) : null}
                </Suspense>
              </main>
            </div>
          </div>
        </div>
      )}
      <LogoutPopup />
    </>
  );
};

export default BaseLayoutWithNavbar;
