import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import { startLoading, stopLoading, fetchNetworks } from './networkSlice';
import { Network } from 'types/network';

//** ASYNC FUNCS */
export const fetchEmergencyNetworks = (): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());

    let networks = await callNetworkContactCarerReadApi();

    dispatch(
      fetchNetworks({
        networks
      })
    );

    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const sendNetworkAccessRequest = (): AppThunk => async dispatch => {
  try {
    await callNetworkCarerRequestAccessApi();
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

//** API FUNCS */

export const callNetworkContactCarerReadApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios
    .get(`/NetworkContact/Carer/Read/${sessionStorage.getItem('UserId')!}`)
    .then(response => {
      const emergencyContacts: Network[] = JSON.parse(
        JSON.stringify(response.data)
      );
      //Jorge Avendano 230920 K08 set profile picture begin
      const updatedNetworks: Network[] = emergencyContacts.map(item => {
        return {
          ...item,
          Type: item.Type ? item.Type : 'Person',
          Name: item.Name ? item.Name : '',
          Phone: item.Phone ? item.Phone : '',
          Image: item.Image
            ? 'data:image/png;base64,' + item.Image
            : item.Type === 'Person'
              ? '/images/avatars/carer.png'
              : '/images/avatars/provider_avatar.png',
          Organization: item.Organization ? item.Organization : '-', //Hammad Tahir T279 031021 - Showing information on network contact when viewed from carer and SP
          Relationship: item.Relationship ? item.Relationship : '-' //Hammad Tahir T279 031021 - Showing information on network contact when viewed from carer and SP
        };
      });
      //Jorge Avendano 230920 K08 end
      return updatedNetworks;
    });
};

const callNetworkCarerRequestAccessApi = () => {
  return axios.post(
    `/NetworkContact/Carer/RequestAccess/${sessionStorage.getItem(
      'RecoveryPlanId'
    )}`
  );
};
