import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import { IconButton, Dialog, DialogContent, Theme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Network } from 'types/network';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducer';
import { Button } from 'common/components';
import useRouter from 'common/utils/useRouter';
import { fetchEmergencyContacts } from 'slices/safety/action';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '10px'
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    // padding: '10px 40px 40px 0'
    width: '350px',
    [theme.breakpoints.down('xs')]: {
      width: '250px'
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '40px',
    position: 'relative'
  },
  titleContainer2: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column'
  },
  titleContainer3: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
    position: 'relative'
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '35px',
    color: '#75B7FF'
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    margin: '10px 0'
  },
  text: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#003E1F'
  },
  text2: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontSize: '12px',
    color: '#003E1F'
  },
  number: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '35px',
    color: '#FA7268'
  },
  iconButton: {
    position: 'absolute',
    top: '4px',
    right: '-7px',
    padding: '0'
  },
  buttonContainer: {
    marginTop: '10px',
    width: '100%'
  }
}));

interface Props {
  open: boolean;
  close: () => void;
}

const Emergency: React.FC<Props> = ({ open, close }) => {
  const classes = useStyles();
  const { history } = useRouter();
  const dispatch = useDispatch();

  const people: Network[] = useSelector(
    (state: RootState) => state.safety.people
  );

  const organisations: Network[] = useSelector(
    (state: RootState) => state.safety.organisations
  );

  useEffect(() => {
    dispatch(fetchEmergencyContacts());
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title">
      <DialogContent>
        <div className={classes.root}>
          <div className={classes.titleContainer}>
            <div className={classes.titleContainer2}>
              {organisations.length !== 0 &&
                sessionStorage.getItem('FirstName') !== '' && (
                  <span className={classes.title}>
                    {sessionStorage.getItem('FirstName') + `'s`}
                  </span>
                )}
              <div className={classes.titleContainer3}>
                <img
                  src="/images/goal/emergency.svg"
                  alt=""
                  style={{ marginRight: '12px' }}
                />
                <span className={classes.title}>Click to Call</span>
              </div>
            </div>
            <IconButton className={classes.iconButton} onClick={close}>
              <Close
                fontSize="large"
                style={{ fill: '#9CA4B4', marginRight: '-10px' }}
              />
            </IconButton>
          </div>
          <div className={classes.container}>
            {organisations.concat(people).length !== 0 ? (
              organisations.concat(people).map((network, index) => {
                return (
                  <div className={classes.content} key={index}>
                    <span className={classes.text}>{network.Name}</span>
                    <span>
                      <a
                        className={classes.number}
                        href={`tel:${network.Phone}`}>
                        {network.Phone}
                      </a>
                    </span>
                  </div>
                );
              })
            ) : (
              <div>
                <div className={classes.content}>
                  <span className={classes.text}>Emergency services</span>
                  <span>
                    <a className={classes.number} href="tel:000">
                      000
                    </a>
                  </span>
                </div>
                <div className={classes.content}>
                  <span className={classes.text}>AccessLine</span>
                  <span>
                    <a className={classes.number} href="tel:1800800944">
                      1800 800 944
                    </a>
                  </span>
                </div>
                <div className={classes.content}>
                  <span className={classes.text}>LifeLine</span>
                  <span>
                    <a className={classes.number} href="tel:131114">
                      13 11 14
                    </a>
                  </span>
                </div>
                <div className={classes.content}>
                  <span className={classes.text}>
                    Suicide Call Back Service
                  </span>
                  <span>
                    <a className={classes.number} href="tel:130659467">
                      1300 659 467
                    </a>
                  </span>
                </div>
                {sessionStorage.getItem('FirstName') === '' ||
                sessionStorage.getItem('FirstName') === null ? (
                  <div>
                    <div className={classes.content}>
                      <span className={classes.text2}>
                        These numbers are available 24/7 in case of emergency,
                        NSW wide. These numbers are included in this list by
                        default.
                        <br />
                        <br />
                        If you want to access the Emergency click-to-call list
                        of a specific person, make sure you have selected them
                        in 'My People' first.
                      </span>
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button
                        type="tertiary"
                        click={
                          history.location.pathname === '/mypeople'
                            ? close
                            : () => history.push('/mypeople')
                        }>
                        Select a person
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={classes.content}>
                      <span className={classes.text2}>
                        These numbers are available 24/7 in case of emergency,
                        NSW wide. These numbers are included in this list by
                        default.
                        <br />
                        <br />
                        If you want to see this person's click-to-call list, you
                        will need to request access to their network and safety
                        plan first.
                      </span>
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button
                        type="tertiary"
                        click={
                          history.location.pathname === '/safety'
                            ? close
                            : () => history.push('/safety')
                        }>
                        Check Safety Plan
                      </Button>
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button
                        type="tertiary"
                        click={
                          history.location.pathname === '/networks/people'
                            ? close
                            : () => history.push('/networks/people')
                        }>
                        Check Network
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Emergency;
