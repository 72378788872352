import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import useRouter from 'common/utils/useRouter';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  Divider,
  Paper,
  Avatar,
  Dialog,
  DialogContent,
  Slide,
  Hidden,
  IconButton
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { TransitionProps } from '@material-ui/core/transitions/transition';

import { Navigation, Emergency } from 'common/components';
import navigationConfig from '../../navigationConfig';
import NavProfile from '../NavProfile';
import { useTheme } from '@material-ui/core/styles';
// Jorge Avendano T29 070820 get notifications begin
import Badge from '@material-ui/core/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { getUnreadNotificationApi } from 'slices/notifications/action';
import { RootState } from 'reducer';
// Jorge Avendano T29 070820 end

//Jorge Avendano T8 260820 Implementation of push notifications through the browser begin
import { NotificationItem } from 'types/notification';
import addNotification from 'react-push-notification';
//Jorge Avendano T8 end

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    background: '#75B7FF'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '2em'
  },
  person: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 118,
    height: 118,
    // Smarth Bajaj changes
    // backgroundColor: '#D5F2E3',
    backgroundColor: '#175BA5',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#DBF5FD'
    },
    '&:active': {
      backgroundColor: '#DBF5FD'
    }
  },
  carer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px'
  },
  avatar: {
    width: 60,
    height: 60,
    boxShadow: '3px 5px 5px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer'
  },
  bigAvatar: {
    width: 100,
    height: 100
  },
  name: {
    marginTop: '8px',
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#003E1F'
  },
  divider: {
    marginTop: '16px'
  },
  navigation: {
    marginTop: '16px'
  },
  navProfile: {
    zIndex: 3,
    bottom: '0',
    left: '0',
    position: 'fixed',
    background: '#FFFFFF',
    borderRadius: '6px',
    width: '240px',
    height: '580px'
  },
  text: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '127%',
    // Smarth Bajaj changes
    // color: '#37474F'
    color: '#FFFFFF'
  },
  customBadge: {
    // Jorge Avendano T29 070820 dot style
    backgroundColor: '#FA7268',
    padding: '4px',
    paddingBottom: '4px',
    fontSize: '16px'
  }
}));

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) => <Slide direction="right" ref={ref} {...props} />
);

type Props = {
  className: string;
  onMobileClose: () => void;
  openMobile: boolean;
};

const NavBar: React.FC<Props> = ({
  openMobile,
  onMobileClose,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const router = useRouter();
  const { history } = router;

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    //eslint-disable-next-line
  }, [router.location.pathname]);

  // Jorge Avendano T29 070820 Get unread notifications and show dot begin
  // const count: string = useSelector(
  //   (state: RootState) => state.notifications.count
  // );

  const unReadNotifications: NotificationItem[] = useSelector(
    (state: RootState) => state.notifications.unReadNotifications
  );

  const dispatch = useDispatch();
  let hideDot = true;

  sessionStorage.setItem('notifSession', unReadNotifications.length.toString());
  hideDot = sessionStorage.getItem('notifSession') !== '0' ? false : true;

  useEffect(() => {
    dispatch(getUnreadNotificationApi());
  }, [dispatch]);
  // Jorge Avendano T29 070820 end

  //Jorge Avendano T8 260820 Implementation of push notifications through the browser begin

  let notificationTitle = '';
  let notificationIcon = '';
  let shownNotifications = '';
  if (sessionStorage.getItem('shownNotifications') != null)
    shownNotifications = JSON.parse(sessionStorage.getItem('shownNotifications')!);

  if (unReadNotifications.length > 0) {
    for (let item of unReadNotifications) {
      if (!shownNotifications.includes(item.Id)) {
        if (item.NotificationType === 'Comment') {
          notificationTitle = 'You have a new comment';
          notificationIcon = 'https://recoveryplanstorage.blob.core.windows.net/assets/Comment.png';
        }
        if (item.NotificationType === 'Suggestion') {
          notificationTitle = 'You have a new suggestion';
          notificationIcon = 'https://recoveryplanstorage.blob.core.windows.net/assets/Suggestions.png';
        }
        if (item.NotificationType === 'AccessRequest') {
          notificationTitle = 'Access request';
          notificationIcon = 'https://recoveryplanstorage.blob.core.windows.net/assets/Request.png';
        }
        if (item.NotificationType === 'System') {
          notificationTitle = 'Goal reminder';
          notificationIcon = 'https://recoveryplanstorage.blob.core.windows.net/assets/Reminder.png';
        }
        if (item.NotificationType === 'Update') {
          notificationTitle = '';
          notificationIcon = 'https://recoveryplanstorage.blob.core.windows.net/assets/Update.png';
        }
        addNotification({
          title: notificationTitle,
          message: item.Message.replace('<strong>', '').replace('</strong>', ''),
          duration: 10000,
          icon: notificationIcon,
          //theme: 'darkblue',
          native: true // when using native, your OS will handle theming.
        });
        notificationTitle = '';
        notificationIcon = '';
        shownNotifications = shownNotifications + ',' + item.Id;
      }
    }
    sessionStorage.setItem('shownNotifications', JSON.stringify(shownNotifications));
  }
  //Jorge Avendano T8 260820 end

  /** Dialog */
  const [open, setOpen] = useState(false);
  const handleClose: () => void = () => {
    setOpen(false);
  };
  const handleClickOpen: () => void = () => {
    onMobileClose();
    setOpen(true);
  };

  /** Emergency dialog */
  const [open2, setOpen2] = useState(false);

  function handleClickOpen2() {
    setOpen2(true);
  }

  function handleClose2() {
    setOpen2(false);
  }

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));

  const navbarContent = (
    <Fragment>
      <div className={classes.iconContainer}>
        {/* Smarth Bajaj changes */}
        {matchesSM ? (
          <IconButton style={{ padding: '' }} onClick={() => onMobileClose()}>
            <Close
              fontSize="large"
              style={{ fill: '#FFFFFF', fontSize: '35px' }}
            />
          </IconButton>
        ) : (
          undefined
        )}
        {/*  */}
      </div>
      <div className={classes.person}>
        <div className={classes.avatarContainer}>
          {sessionStorage.getItem('UserId') !== null &&
          sessionStorage.getItem('UserId') !== '' ? (
            <Avatar
              alt=""
              className={classes.bigAvatar}
              src={sessionStorage.getItem('Photo')!}
              onClick={() => history.push('/dashboard')}
            />
          ) : (
            // Smarth Bajaj Changes - 18/Sept/2020 - Additional task where clicking on the profile icon takes to my people page when the user is not selected
            <div
              style={{ textAlign: 'center' }}
              onClick={() => history.push('/mypeople')}>
              <span className={classes.text}>
                Please
                <br />
                select a
                <br />
                person
              </span>
            </div>
          )}
        </div>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation key={list.title} pages={list.pages} title={list.title} />
        ))}
      </nav>
      <Divider className={classes.divider} />
      <div className={classes.carer} onClick={handleClickOpen2}>
        <img
          src="/images/goal/emergency.svg"
          alt=""
          style={{ margin: '15px 0', cursor: 'pointer' }}
        />
      </div>
      <div className={classes.carer} onClick={handleClickOpen}>
        {/* Jorge Avendano T29 070820 add notification dot*/}
        <Badge
          badgeContent={sessionStorage.getItem('notifSession')}
          invisible={hideDot}
          color="error"
          overlap="circle"
          classes={{ badge: classes.customBadge }}>
          <Avatar
            alt=""
            className={classes.avatar}
            src={sessionStorage.getItem('Carer_Avatar')!}
          />
        </Badge>
      </div>
    </Fragment>
  );

  const navProfileDialog = (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}>
      <DialogContent className={classes.navProfile}>
        <NavProfile close={handleClose} />
      </DialogContent>
    </Dialog>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square>
          {navbarContent}
        </Paper>
      </Hidden>
      {open && navProfileDialog}
      {open2 && <Emergency open={open2} close={handleClose2} />}
    </Fragment>
  );
};

export default NavBar;
