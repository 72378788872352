import React, { Fragment, Suspense } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';

import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  content: {
    background: '#FFFFFF',
    height: '100%',
    minHeight: '100%'
  }
}));

const BaseLayout: React.FC<RouteConfigComponentProps> = ({ route }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          {route ? renderRoutes(route.routes) : null}
        </Suspense>
      </main>
    </Fragment>
  );
};

export default BaseLayout;
