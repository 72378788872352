import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import { fetch, startLoading, stopLoading } from './peopleSlice';
import { Person } from 'types/people';
import { callRecoveryPlanGetRecoveryUpdateApi } from 'slices/dashboard/action';

//Hammad Tahir - addContactManuallyFix - 110821
import { callInvitationReadApi, deleteInvitation } from 'slices/invitation/action';

//** ASYNC FUNCS */
export const fetchPeople = (): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());
    const people = await callRecoveryPlanListApi();

    //Hammad Tahir - addContactManuallyFix - 110821 - Start
    const receivedInvitations = await callInvitationReadApi();

    for(const invitation of receivedInvitations){
      if(invitation.AcceptedOn !== null)
      {
          await dispatch(deleteInvitation(invitation.InvitationId))
      }
    }
    const receivedInvitations2 = await callInvitationReadApi();
    //Hammad Tahir - addContactManuallyFix - End

    const updatedPeople: Person[] = [];
    for (const person of people) {
      //Hammad Tahir - addContactManuallyFix - 110821 - Start
      if(receivedInvitations2.find(item => item.UserId === person.UserId)){
        continue
      }
      //Hammad Tahir - addContactManuallyFix - End

      const lastUpdate = await callRecoveryPlanGetRecoveryUpdateApi(
        person.RecoveryPlanId,
        person.LastRecPlanUpdate
      );
      const hasUpdate =
        lastUpdate.NewGoalCount > 0 ||
        lastUpdate.NewGoalStepCount > 0 ||
        lastUpdate.NewGoalStepCheckinCount > 0 ||
        lastUpdate.NewJournalEntryCount > 0;

      const updatedPerson: Person = {
        ...person,
        ImageUrl: person.ImageUrl
          ? person.ImageUrl
          : '/images/avatars/jiembaDefaultAvatar.svg',
        HasUpdate: hasUpdate
      };

      updatedPeople.push(updatedPerson);
    }

    await dispatch( //Hammad Tahir - addContactManuallyFix - 110821 - Await added
      fetch({
        people: updatedPeople
      })
    );
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const selectPerson = (
  history: any,
  person: Person,
  redirection: string//Jorge Avendano T32.2 140920 Notifications redirection
): AppThunk => async dispatch => {
  try {
    sessionStorage.setItem('UserId', person.UserId);
    sessionStorage.setItem('RecoveryPlanId', person.RecoveryPlanId);
    sessionStorage.setItem('FirstName', person.FirstName);
    sessionStorage.setItem('SurName', person.Surname);
    sessionStorage.setItem(
      'Photo',
      person.Photo
        ? 'data:image/png;base64,' + person.Photo
        : '/images/avatars/jiembaDefaultAvatar.svg'
    );
    sessionStorage.setItem('LastRecPlanUpdate', person.LastRecPlanUpdate);
    if (redirection !== '')
      history.push(redirection);//Jorge Avendano T32.2 140920 Notifications redirection
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

//** API FUNCS */
export const callRecoveryPlanListApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.get('/RecoveryPlan/List').then(response => {
    const people: Person[] = JSON.parse(JSON.stringify(response.data));
    return people;
  });
};
