/* eslint-disable react/display-name */
import React, { Fragment, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, colors, Theme } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) => ({
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  buttonLeaf: {
    color: colors.blueGrey[800],
    padding: '8px',
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    opacity: 0.8,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      backgroundColor: '#DBF5FD',
      opacity: 1
    }
  },
  active: {
    color: theme.palette.primary.main,
    // Smarth Bajaj changes
    //backgroundColor: theme.palette.primary.dark,
    backgroundColor: '#DBF5FD',
    fontWeight: theme.typography.fontWeightMedium
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '&.active': {
      color: '#000'
    },
    '&:hover': {
      color: '#DBF5FD'
    }
  },
  buttonTextActive: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '15px',
    textAlign: 'center',
    // Smarth Bajaj changes
    //color: '#DBF5FD',
    color: '#175BA5',
    marginTop: '5px',
    textTransform: 'uppercase'
  }
}));

type NavProps = {
  activeClassName: string;
  className: string;
  component: React.FC<any>;
  exact: boolean;
  to: string;
};

type Props = {
  depth: number;
  href: string;
  icon: string;
  iconActive: string;
  title: string;
};

const CustomRouterLink = forwardRef<HTMLDivElement, NavProps>((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const MyExtendedButton: React.FC<ButtonProps & NavProps> = (
  props: ButtonProps & NavProps
) => {
  const { children, ...buttonProps } = props;
  return <Button {...buttonProps}>{children}</Button>;
};

const NavigationListItem: React.FC<Props> = ({
  title,
  href,
  depth,
  icon,
  iconActive
}) => {
  const classes = useStyles();
  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft
  };

  return (
    <ListItem className={classes.itemLeaf} disableGutters>
      {sessionStorage.getItem('UserId') !== null &&
      sessionStorage.getItem('UserId') !== '' ? (
        <MyExtendedButton
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={CustomRouterLink}
          exact
          style={style}
          to={href}>
          <div className={classes.buttonContent}>
            <Fragment>
              <img src={iconActive} alt="" />
              <span className={classes.buttonTextActive}>{title}</span>
            </Fragment>
          </div>
        </MyExtendedButton>
      ) : (
        <Button className={classes.buttonLeaf} style={style}>
          <div className={classes.buttonContent}>
            <Fragment>
              <img src={icon} alt="" />
              <span className={classes.buttonTextActive}>{title}</span>
            </Fragment>
          </div>
        </Button>
      )}
    </ListItem>
  );
};

export default NavigationListItem;
