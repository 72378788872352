import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import { startLoading, stopLoading, fetchLastUpdate } from './dashboardSlice';
import { fetchGoals } from 'slices/goal/action';
import { fetchJournals } from 'slices/journey/action';
import { LastUpdate } from 'types/other';

//** ASYNC FUNCS */
export const fetchDashboardInfo = (): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());

    await dispatch(fetchGoals());
    await dispatch(fetchJournals());
    await dispatch(readLastUpdate());

    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const readLastUpdate = (): AppThunk => async dispatch => {
  try {
    const lastUpdate = await callRecoveryPlanGetRecoveryUpdateApi(
      sessionStorage.getItem('RecoveryPlanId')!,
      sessionStorage.getItem('LastRecPlanUpdate')!
    );
    dispatch(
      fetchLastUpdate({
        lastUpdate
      })
    );
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

//** API FUNS */
export const callRecoveryPlanGetRecoveryUpdateApi = (
  recoveryPlanId: string,
  lastLoginDate: string
) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  const requestBody = {
    RecoveryPlanId: recoveryPlanId,
    LastLoginDate: lastLoginDate
  };

  return axios
    .post('/RecoveryPlan/GetRecoveryUpdate', requestBody)
    .then(response => {
      const lastUpdate: LastUpdate = JSON.parse(JSON.stringify(response.data));
      return lastUpdate;
    });
};
