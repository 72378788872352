import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoryRootType, Strength, Story, AdditionalInfo } from 'types/story'; //Hammad Tahir T252 081021 - Showing "Additional information" in Story
import { FocusArea } from 'types/other';

const initialState: StoryRootType = {
  story: {} as Story,
  strengths: [],
  focusAreas: [],
  additionalInfo: [], //Hammad Tahir T252 081021 - Showing "Additional information" in Story
  loading: false
};

const storySlice = createSlice({
  name: 'story',
  initialState: initialState,
  reducers: {
    fetchStory(state, action: PayloadAction<{ story: Story }>) {
      const { story } = action.payload;
      state.story = story;
      state.loading = false;
    },
    fetchStrenghts(state, action: PayloadAction<{ strengths: Strength[] }>) {
      const { strengths } = action.payload;
      state.strengths = strengths;
    },
    fetchAreas(state, action: PayloadAction<{ focusAreas: FocusArea[] }>) {
      const { focusAreas } = action.payload;
      state.focusAreas = focusAreas;
    },
    //Hammad Tahir T252 081021 - Showing "Additional information" in Story
    fetchAdditionalInfo(state, action: PayloadAction<{ additionalInfo: AdditionalInfo[] }>) {
      const { additionalInfo } = action.payload;
      state.additionalInfo = additionalInfo;
    },
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    }
  }
});

export const {
  fetchStory,
  fetchStrenghts,
  fetchAreas,
  fetchAdditionalInfo, //Hammad Tahir T252 081021 - Showing "Additional information" in Story
  startLoading,
  stopLoading
} = storySlice.actions;
export default storySlice.reducer;
