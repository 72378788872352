import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import { Profile } from 'types/profile';
import { start, end } from './authSlice';

import { callRecoveryPlanListApi, selectPerson } from 'slices/people/action'; //Hammad Tahir - emailRedirection - 011021
import { fetchAllFocusAreas } from 'slices/other/action'; //Hammad Tahir - emailRedirection - 011021
import { fetchGoals } from 'slices/goal/action'; //Hammad Tahir - emailRedirection - 011021

/** ASYNC FUNCS */
export const startSession = (history: any): AppThunk => async dispatch => {
  try {
    await callProfileCheckApi()
      .then(async response => {
        await callProfileReadApi();
        dispatch(start());

        // history.push('/mypeople'); //Hammad Tahir - emailRedirection - 011021
        dispatch(redirectAction(history)); //Hammad Tahir - emailRedirection - 011021
      })
      .catch(async error => {
        await callProfileSetupApi();

        await callProfileReadApi();
        dispatch(start());

        // history.push('/mypeople'); //Hammad Tahir - emailRedirection - 011021
        dispatch(redirectAction(history)); //Hammad Tahir - emailRedirection - 011021
      });
  } catch (err) {
    history.push('/');//Jorge Avendano T43.3 270720 Redirects to the index page in case of any issue
    // dispatch(failed(err.toString()));
  }
};

//Hammad Tahir - emailRedirection - 011021 - Start
export const redirectAction = (history: any): AppThunk => async dispatch => {
  try {
    if (sessionStorage.getItem('target') !== null &&
      sessionStorage.getItem('target') !== '') {
      if (sessionStorage.getItem('userId') !== null &&
        sessionStorage.getItem('userId') !== '') {

        const people = await callRecoveryPlanListApi();

        let target = sessionStorage.getItem('target');
        let userId = sessionStorage.getItem('userId');

        dispatch(fetchAllFocusAreas());

        if (target == 'goals') {
          dispatch(
            selectPerson(history, people.find(item => item.UserId === userId)!, '/goals/completed')
          );
        }
        else if (target == 'goalHelp') {
          sessionStorage.setItem('UserId',userId!);
          await dispatch(fetchGoals());
          await dispatch(
              selectPerson(history, people.find(item => item.UserId === userId)!, `/goals/current/${sessionStorage.getItem('goalId')}`)
          );
        }
        else if (target == 'journal') {
          dispatch(
            selectPerson(history, people.find(item => item.UserId === userId)!, '/journeys/all')
          );
        }
        else if (target == 'story') {
          dispatch(
            selectPerson(history, people.find(item => item.UserId === userId)!, '/story')
          );
        }
        else if (target == 'safety') {
          dispatch(
            selectPerson(history, people.find(item => item.UserId === userId)!, '/safety')
          );
        }
        else if (target == 'network') {
          dispatch(
            selectPerson(history, people.find(item => item.UserId === userId)!, '/networks/people')
          );
        }
        else {
          history.push('/mypeople');
        }
      }
    }
    else {
      history.push('/mypeople');
    }
  } catch (err) {
    history.push('/mypeople');
    // dispatch(failed(err.toString()));
  }
};
//Hammad Tahir - emailRedirection - End

export const endSession = (): AppThunk => async dispatch => {
  try {
    authentication.signOut();
    sessionStorage.clear();
    dispatch(end());
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

//** API FUNCS */
export const callProfileReadApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios.get('/Profile/Read/?contactType=935000002').then(response => {
    const profile: Profile = response.data;
    sessionStorage.setItem('Carer_UserId', profile.UserId);
    sessionStorage.setItem('Carer_SafetyPlanId', profile.SafetyPlanId);
    sessionStorage.setItem('Carer_FirstName', profile.FirstName);
    sessionStorage.setItem('Carer_Surname', profile.Surname);
    sessionStorage.setItem('Carer_ContactId', profile.ContactId);
    sessionStorage.setItem(
      'Carer_EmergencyWhenToContact',
      profile.EmergencyWhenToContact
    );
    sessionStorage.setItem('Carer_UserEmail', profile.UserEmail);
    sessionStorage.setItem(
      'Carer_Avatar',
      profile.Image
        ? 'data:image/png;base64,' + profile.Image
        : '/images/avatars/defaultAvatar.svg'
    );
    sessionStorage.setItem('LoggedInAs',profile.ContactType) //Hammad Tahir - switchAccountFix - 190721
    return profile;
  });
};

export const callProfileSetupApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.post(`/Profile/Setup/935000002/${sessionStorage.getItem('InvitationId')}`); //Hammad Tahir - invitationFix - 110821 - Send inviation Id if profile setup from invitation
};

export const callProfileCheckApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios.post('/Profile/Check', { name: "check" }, { timeout: 10000 });//Jorge Avendano T43.3 270720 Redirects to the index page in case of any issue
};
