import React, { Fragment, useState } from 'react';
import useRouter from 'common/utils/useRouter';
import { useDispatch } from 'react-redux';
import { endSession } from 'slices/auth/action';
import { Emergency } from 'common/components';
import { Avatar, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Badge from '@material-ui/core/Badge'; // Jorge Avendano T29 070820 show notifications begin

import {
  Notifications,
  SettingsApplications,
  Phone,
  Person,
  Close,
  People
} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative'
  },
  item: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '5px 0',
    cursor: 'pointer'
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10px'
  },
  avatar: {
    width: 60,
    height: 60,
    boxShadow: '3px 5px 5px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer'
  },
  name: {
    marginTop: '10px',
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#003E1F'
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#37474F'
  },
  closeIcon: {
    position: 'absolute',
    bottom: '-30px',
    right: '-20px'
  },
  customBadge: {
    // Jorge Avendano T29 070820 dot style
    backgroundColor: '#FA7268',
    padding: '4px',
    paddingBottom: '4px',
    fontSize: '16px',
    margin: '10px'
  }
}));

type Props = {
  close: () => void;
};

const NavProfile: React.FC<Props> = ({ close }) => {
  const classes = useStyles();
  const { history } = useRouter();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(endSession());
  };

  const handleMenuClick = (menu: string) => {
    if (menu === 'profile') {
      history.push(`/profile`);
    } else if (menu === 'notification') {
      history.push('/notification');
    } else if (menu === 'settings') {
      history.push('/settings');
    } else if (menu === 'about') {
      history.push('/about');
    } else if (menu === 'people') {
      sessionStorage.setItem('UserId', '');
      sessionStorage.setItem('RecoveryPlanId', '');
      sessionStorage.setItem('FirstName', '');
      sessionStorage.setItem('SurName', '');
      sessionStorage.setItem('Photo', '');
      history.push('/mypeople');
    }
    close();
  };

  /* Smarth Bajaj Changes - 18/Sept/2020 - Task #K02 */
  /** Dialog for emergency numbers */
  const [open, setOpen] = useState(false);

  const closeEmergencyHandler = () => {
    setOpen(false);
    close();
  };

  const clickAboutHandler = () => {
    window.open('https://journi.aihealth.tech/caring', '_blank');
    close();
  };

  // Jorge Avendano T29 070820 show or hide dot
  let hideDot = sessionStorage.getItem('notifSession') != '0' ? false : true;

  return (
    <Fragment>
      <div
        className={classes.avatarContainer}
        onClick={() => handleMenuClick('profile')}>
        <Avatar
          alt=""
          className={classes.avatar}
          src={sessionStorage.getItem('Carer_Avatar')!}
        />
        <Typography className={classes.name}>
          {sessionStorage.getItem('Carer_FirstName')}
        </Typography>
      </div>
      <div className={classes.container}>
        <div className={classes.item} onClick={() => handleMenuClick('people')}>
          <IconButton>
            <People style={{ fill: '#37474F' }} />
          </IconButton>
          <span className={classes.text}>My people</span>
        </div>
        <Badge
          badgeContent={sessionStorage.getItem('notifSession')}
          invisible={hideDot}
          color="error"
          overlap="circle"
          classes={{ badge: classes.customBadge }}>
          <div
            className={classes.item}
            onClick={() => handleMenuClick('notification')}>
            <IconButton>
              <Notifications style={{ fill: '#FA7268' }} />
            </IconButton>
            <span className={classes.text}>Notifications</span>
          </div>
        </Badge>
        <div
          className={classes.item}
          onClick={() => handleMenuClick('settings')}>
          <IconButton>
            <SettingsApplications />
          </IconButton>
          <span className={classes.text}>Settings</span>
        </div>
        {/* Smarth Bajaj Changes - 18/Sept/2020 - Task #K02 */}
        <div className={classes.item} onClick={() => setOpen(true)}>
          <IconButton>
            <Phone style={{ fill: '#fa9419' }} />
          </IconButton>
          <span className={classes.text}>Emergency</span>
        </div>
        {/* Smarth Bajaj Changes */}
        <div className={classes.item} onClick={() => handleMenuClick('about')}>
          <IconButton>
            <Person />
          </IconButton>
          <span className={classes.text}>About</span>
        </div>
        <div className={classes.item} onClick={handleLogout}>
          <IconButton>
            <img src="/images/settings/logout.svg" alt="" />
          </IconButton>
          <span className={classes.text}>Log out</span>
        </div>
        <IconButton className={classes.closeIcon} onClick={close}>
          <Close fontSize="large" style={{ fill: '#75B7FF' }} />
        </IconButton>
      </div>
      {/* Smarth Bajaj Changes - 18/Sept/2020 - Task #K02 */}
      {open && <Emergency open={open} close={closeEmergencyHandler} />}
    </Fragment>
  );
};

export default NavProfile;
