import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: '18px',
    backgroundColor: '#F3F3F3',
    padding: 15
  },
  avatar: {
    width: 50,
    height: 50
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: '127.69%',
    color: '#B3B3B3'
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#73BA9B'
  },
  replyTextField: {
    boxSizing: 'border-box',
    boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.2)',
    background: '#EEEEEE',
    borderRadius: '3px',
    margin: '0 10px'
  },
  personName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 15,
    color: '#37474F'
  },
  message: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#323F45'
  }
}));

type Props = {
  commentId: string;
  id: string;
  name: string;
  message: string;
  favorite?: boolean;
  date: string;
};

const CommentReply: React.FC<Props> = ({
  commentId,
  id,
  name,
  message,
  date,
  favorite = true
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={11}>
          <span className={classes.personName}>{name}</span>
          {/* <span style={{ fontSize: 18, fontFamily: 'Roboto' }}><b>{name}</b></span> */}
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={12}>
          <div style={{ overflowWrap: 'break-word' }}>
            <span className={classes.message}>{message}</span>
          </div>
          <div style={{ marginTop: '5px' }}>
            <span className={classes.text}>{moment(date).format('LLLL')}</span>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CommentReply;
