import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import {
  fetch,
  stopLoading,
  startLoading,
  fetchComments,
  fetchJournalsChart
} from './journeySlice';
import {
  Journal,
  JournalList,
  JournalComment,
  JournalChart
} from 'types/journey';
import moment from 'moment';
import uuid from 'uuid';
import { fetchEmergencyNetworks } from 'slices/network/action';//Jorge Avendano 230920 K08 Load network

//** ASYNC FUNCS */

export const fetchJournals = (): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());
    const journalsList = await callFetchJournalsListApi();
    await Promise.all(journalsList.map(callReadJournalDetailApi)).then(
      journalsData => {
        //Hammad Tahir T296 031021 - [Carer] Journal entries order and clean the Journey - Start
        const sortedJournalsData = journalsData.sort(
          (b, a) =>
            new Date(a.CreatedOnDate).getTime() -
            new Date(b.CreatedOnDate).getTime()
        );
        //Hammad Tahir T296 031021 - End

        dispatch(
          fetch({
            journals: sortedJournalsData
          })
        );

        const converter = (
          feelingStr: 'VerySad' | 'Sad' | 'Neutral' | 'Happy' | 'VeryHappy' | ''
        ) => {
          switch (feelingStr) {
            case 'VerySad': {
              return 1;
            }
            case 'Sad': {
              return 2;
            }
            case 'Neutral': {
              return 3;
            }
            case 'Happy': {
              return 4;
            }
            case 'VeryHappy': {
              return 5;
            }
            default:
              return 1;
          }
        };

        //create journal chart data
        const journalsChart: JournalChart[] = [];
        journalsData.forEach(item => {
          const journalChart: JournalChart = {
            Id: item.Id,
            Message: item.Message,
            CreatedOnDate: item.CreatedOnDate,
            HowAreYouFeeling: converter(item.HowAreYouFeeling)
          };
          journalsChart.push(journalChart);
        });

        // Issue 22 - Group by Date - Prafful Jagtap
        const newValues = Array.from(
          new Set(
            journalsChart.map(item =>
              moment(item.CreatedOnDate).format('YYYY-MM-DD')
            )
          )
        ).map(item => {
          let moods: (1 | 2 | 3 | 4 | 5)[] = [];
          let data = journalsChart
            .filter(each => {
              return moment(each.CreatedOnDate).format('YYYY-MM-DD') === item;
            })
            .reduce((a, b) => {
              moods.push(b.HowAreYouFeeling);
              return a;
            });
          moods.push(data.HowAreYouFeeling);
          const avgMood = moods.reduce((a, b) => a + b, 0) / moods.length;
          data.HowAreYouFeeling = Math.round(avgMood) as 1 | 2 | 3 | 4 | 5;
          return data;
        });

        const sortedChartData = newValues.sort(
          // const sortedChartData = journalsChart.sort(
          (a, b) =>
            new Date(a.CreatedOnDate).getTime() -
            new Date(b.CreatedOnDate).getTime()
        );

        dispatch(fetchJournalsChart({ journalsChart: sortedChartData }));
      }
    );
    await dispatch(fetchEmergencyNetworks());//Jorge Avendano 230920 K08 Load network
    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const filterJournalsByDateRange = (
  filterType: 'all' | 'week' | 'month' | 'year'
): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());

    const journalsList = await callFetchJournalsListApi();
    await Promise.all(journalsList.map(callReadJournalDetailApi)).then(
      journalsData => {
        dispatch(
          fetch({
            journals: journalsData
          })
        );

        const converter = (
          feelingStr: 'VerySad' | 'Sad' | 'Neutral' | 'Happy' | 'VeryHappy' | ''
        ) => {
          switch (feelingStr) {
            case 'VerySad': {
              return 1;
            }
            case 'Sad': {
              return 2;
            }
            case 'Neutral': {
              return 3;
            }
            case 'Happy': {
              return 4;
            }
            case 'VeryHappy': {
              return 5;
            }
            default:
              return 1;
          }
        };

        //create journal chart data
        const journalsChart: JournalChart[] = [];
        journalsData.forEach(item => {
          const journalChart: JournalChart = {
            Id: item.Id,
            Message: item.Message,
            CreatedOnDate: item.CreatedOnDate,
            HowAreYouFeeling: converter(item.HowAreYouFeeling)
          };
          journalsChart.push(journalChart);
        });

        // Issue 22 - Group by Date - Prafful Jagtap
        const newValues = Array.from(
          new Set(
            journalsChart.map(item =>
              moment(item.CreatedOnDate).format('YYYY-MM-DD')
            )
          )
        ).map(item => {
          let moods: (1 | 2 | 3 | 4 | 5)[] = [];
          let data = journalsChart
            .filter(each => {
              return moment(each.CreatedOnDate).format('YYYY-MM-DD') === item;
            })
            .reduce((a, b) => {
              moods.push(b.HowAreYouFeeling);
              return a;
            });
          moods.push(data.HowAreYouFeeling);
          const avgMood = moods.reduce((a, b) => a + b, 0) / moods.length;
          data.HowAreYouFeeling = Math.round(avgMood) as 1 | 2 | 3 | 4 | 5;
          return data;
        });

        const sortedChartData = newValues.sort(
          // const sortedChartData = journalsChart.sort(
          (a, b) =>
            new Date(a.CreatedOnDate).getTime() -
            new Date(b.CreatedOnDate).getTime()
        );

        const startDateRange =
          filterType === 'week'
            ? moment()
              .subtract(1, 'weeks')
              .startOf('week')
              .format('YYYY-MM-DD')
            : filterType === 'month'
              ? moment()
                .subtract(1, 'months')
                .startOf('month')
                .format('YYYY-MM-DD')
              : filterType === 'year'
                ? moment()
                  .startOf('year')
                  .format('YYYY-MM-DD')
                : moment()
                  .subtract(1, 'years')
                  .startOf('year')
                  .format('YYYY-MM-DD');

        const filteredSortedChartData = sortedChartData.filter(
          data => new Date(data.CreatedOnDate) > new Date(startDateRange)
        );

        dispatch(
          fetchJournalsChart({ journalsChart: filteredSortedChartData })
        );
      }
    );

    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const callFetchJournalsListApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios
    .get(`/JournalShare/Carer/List/${sessionStorage.getItem('UserId')}`)
    .then(response => {
      let journalsList: JournalList[] = JSON.parse(
        JSON.stringify(response.data)
      );
      return journalsList;
    });
};

export const addNewComment = (
  journalId: string,
  message: string,
  personName: string,
  parentId: string
): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());
    await callCreateCommentApi(journalId, message, personName, parentId);

    const comments = await callFetchCommentApi(journalId);
    dispatch(fetchComments({ comments }));

    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const fetchJournalComments = (
  journalId: string
): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());
    const comments = await callFetchCommentApi(journalId);
    dispatch(fetchComments({ comments }));
    dispatch(stopLoading());
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const sendJournalAccessRequest = (): AppThunk => async dispatch => {
  try {
    await callJournalCarerRequestAccessApi();
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

//** API FUNCS */
export const callReadJournalDetailApi = (journal: JournalList) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  const url = `/Journal/Carer/Read/${sessionStorage.getItem('UserId')}/${journal.JournalId
    }`;
  return axios.get(url).then(response => {
    const journal: Journal = JSON.parse(JSON.stringify(response.data));
    return journal;
  });
};

const callFetchCommentApi = (journalId: string) => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();

  return axios
    .get(
      `/JournalComment/List/${journalId}/${sessionStorage.getItem('UserId')}`
    )
    .then(async response => {
      const comments: JournalComment[] = JSON.parse(
        JSON.stringify(response.data)
      );

      // New Code
      const parentComments = comments.filter(each_comment => {
        return (
          each_comment.ParentCommentId ===
          '00000000-0000-0000-0000-000000000000'
        );
      });
      const childComments = comments.filter(each_comment => {
        return (
          each_comment.ParentCommentId !==
          '00000000-0000-0000-0000-000000000000'
        );
      });
      parentComments.forEach(parent => {
        parent.children = [];
      });
      childComments.forEach(child => {
        const parentId = parentComments.findIndex(parent => {
          return parent.Id === child.ParentCommentId;
        });
        parentComments[parentId].children.push(child);
      });
      // New Code

      const sortedComments = parentComments.sort(
        (a, b) =>
          new Date(b.CreatedOnDate).getTime() -
          new Date(a.CreatedOnDate).getTime()
      );
      return sortedComments;
    });
};

const callCreateCommentApi = (
  journalId: string,
  message: string,
  personName: string,
  parentId: string
) => {
  const requestContent = {
    Id: uuid(),
    ParentCommentId: parentId,
    JournalId: journalId,
    Message: message,
    PersonName: personName,
    CreatedOnDate: moment()
      .toDate()
      .toDateString(),
    NetworkContactId: sessionStorage.getItem('Carer_ContactId')
  };

  return axios.post('/JournalComment/Create', requestContent);
};

const callJournalCarerRequestAccessApi = () => {
  return axios.post(
    `/Journal/Carer/RequestAccess/${sessionStorage.getItem('RecoveryPlanId')}`
  );
};
