import React, { Fragment, useState } from 'react';

import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  Theme,
  Grid
} from '@material-ui/core';

import { Button } from 'common/components';
import Loader from './Loader';
import { sendGoalAccessRequest } from 'slices/goal/action';
import { sendJournalAccessRequest } from 'slices/journey/action';
import { sendSafetyAccessRequest } from 'slices/safety/action';
import { sendNetworkAccessRequest } from 'slices/network/action';
import { sendMyStoryAccessRequest } from 'slices/story/action';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  messageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '11em',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '70%',
      marginTop: '2em'
    }
  },
  info: {
    fontFamily: 'Thasadith',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '23px',
    textAlign: 'left',
    color: '#B3B3B3',
    [theme.breakpoints.up('md')]: {
      fontSize: '22px',
      lineHeight: '23px'
    }
  },
  buttonContainer: {
    zIndex: 200,
    width: '100%',
    margin: '20px 0'
  },
  image: {
    zIndex: 100,
    height: 'auto',
    position: 'fixed',
    top: 0,
    overflow: 'hidden',
    right: -50,
    width: '330px',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '450px',
      top: '155px'
    },
    [theme.breakpoints.down('sm')]: {
      objectFit: 'cover',
      maxWidth: '235px',
      width: '100%',
      top: '150px'
    }
  }
}));

type Props = {
  type: 'Goals' | 'Journey' | 'Story' | 'Safety Plan' | 'Network';
};

const AccessRequest: React.FC<Props> = ({ type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  /** Theme */
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  /** Dialog */
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClick = () => {
    handleClickOpen();

    type === 'Goals' && dispatch(sendGoalAccessRequest());
    type === 'Journey' && dispatch(sendJournalAccessRequest());
    type === 'Safety Plan' && dispatch(sendSafetyAccessRequest());
    type === 'Network' && dispatch(sendNetworkAccessRequest());
    type === 'Story' && dispatch(sendMyStoryAccessRequest());

    setTimeout(() => {
      handleClose();
    }, 3000);
  };

  const loaderDialog = (
    <Dialog
      open={open}
      keepMounted
      fullScreen={fullScreen}
      onClose={handleClose}>
      <DialogContent style={{ padding: '0' }}>
        <Loader />
      </DialogContent>
    </Dialog>
  );

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid container justify="flex-start">
          {matchesSM ? undefined : <Grid item xs={1} sm={2} md={4} />}
          <Grid
            item
            xs={8}
            sm={6}
            md={4}
            style={{ marginLeft: '2em' }}
            container
            justify="flex-start">
            <div className={classes.messageContainer}>
              <div className={classes.info}>
                <p>Seems like there’s nothing to see here!</p>
                <p>There are two possible reasons: </p>
                <p style={{ marginTop: '1em' }}>
                  1. You don’t have access to{' '}
                  {` ${sessionStorage.getItem('FirstName')}’s ${type}`}
                </p>
                <p>
                  2. {` ${sessionStorage.getItem('FirstName')}’s ${type}`} is
                  still blank
                </p>{' '}
                <p style={{ marginTop: '1em' }}>
                  Use the button to let{' '}
                  {` ${sessionStorage.getItem('FirstName')}`} know you want to
                  know more!
                </p>{' '}
              </div>
              <div className={classes.buttonContainer}>
                <Button type="tertiary" click={handleClick}>
                  I want to see this
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={3} sm={4} container justify="flex-end">
            {fullScreen ? (
              <img
                src="/images/request/kirratwo.png"
                alt=""
                className={classes.image}
              />
            ) : (
              <img
                src="/images/request/request_image.svg"
                alt=""
                className={classes.image}
              />
            )}
          </Grid>
        </Grid>
      </div>
      {open && loaderDialog}
    </Fragment>
  );
};

export default AccessRequest;
