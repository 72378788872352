/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { RouteConfig } from 'react-router-config';
import authentication from '@kdpw/msal-b2c-react';

import { AuthLayout, BaseLayout, BaseLayoutWithNavbar } from './layouts';
import { Redirect } from 'react-router-dom';

const routes: RouteConfig[] = [
  {
    path: '/invitation',
    component: BaseLayout,
    routes: [
      {
        path: '/invitation',
        exact: true,
        component: lazy(() => import('views/Invitation'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: BaseLayout,
    routes: [
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Errors/Error404'))
      },
      //Hammad Tahir - switchAccountFix - 190721 - New route added for access denied page
      {
        path: '/errors/error-403',
        exact: true,
        component: lazy(() => import('views/Errors/Error403'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/mypeople',
    component: authentication.required(BaseLayoutWithNavbar),
    exact: true,
    routes: [
      {
        path: '/mypeople',
        exact: true,
        component: authentication.required(lazy(() => import('views/MyPeople')))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/dashboard',
    component: authentication.required(BaseLayoutWithNavbar),
    exact: true,
    routes: [
      {
        path: '/dashboard',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/Dashboard'))
        )
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/auth',
    component: authentication.required(AuthLayout),
    routes: [
      {
        path: '/auth',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/Auth/Login'))
        )
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  // Jorge Avendano
  //in the Sign up authentication page if the user clicks cancel,
  //it will redirect to https://kirra.azurewebsites.net/null
  //this route will redirect to the Index instead
  {
    path: '/null',
    component: BaseLayout,
    routes: [
      {
        component: () => <Redirect to="/" />
      }
    ]
  },
  {
    path: '/goals',
    component: authentication.required(BaseLayoutWithNavbar),
    routes: [
      {
        path: '/goals/:tab',
        exact: true,
        component: authentication.required(lazy(() => import('views/Goal')))
      },
      {
        path: '/goals/:tab/:id',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/Goal/GoalDetails'))
        )
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/journeys',
    component: authentication.required(BaseLayoutWithNavbar),
    routes: [
      {
        path: '/journeys/:tab',
        exact: true,
        component: authentication.required(lazy(() => import('views/Journey')))
      },
      {
        path: '/journeys/:tab/:id',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/Journey/JournalDetail'))
        )
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/story',
    exact: true,
    component: authentication.required(BaseLayoutWithNavbar),
    routes: [
      {
        path: '/story',
        exact: true,
        component: authentication.required(lazy(() => import('views/MyStory')))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/safety',
    component: authentication.required(BaseLayoutWithNavbar),
    routes: [
      {
        path: '/safety',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/SafetyPlan'))
        )
      },
      {
        path: '/safety/staywell',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/SafetyPlan/StayWell'))
        )
      },
      {
        path: '/safety/stress',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/SafetyPlan/Stress'))
        )
      },
      {
        path: '/safety/service',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/SafetyPlan/Service'))
        )
      },
      {
        path: '/safety/unwell',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/SafetyPlan/Unwell'))
        )
      },
      {
        path: '/safety/warningsign',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/SafetyPlan/WarningSign'))
        )
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/networks',
    component: authentication.required(BaseLayoutWithNavbar),
    routes: [
      {
        path: '/networks/:tab',
        exact: true,
        component: authentication.required(lazy(() => import('views/Network')))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/profile',
    component: authentication.required(BaseLayoutWithNavbar),
    routes: [
      {
        path: '/profile',
        exact: true,
        component: authentication.required(lazy(() => import('views/Profile')))
      },
      {
        path: '/profile/edit',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/Profile/EditProfile'))
        )
      },
      {
        path: '/profile/create',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/Profile/CreateProfile'))
        )
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/notification',
    component: authentication.required(BaseLayoutWithNavbar),
    routes: [
      {
        path: '/notification',
        exact: true,
        component: authentication.required(
          lazy(() => import('views/Notification'))
        )
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/settings',
    component: authentication.required(BaseLayoutWithNavbar),
    routes: [
      {
        path: '/settings',
        exact: true,
        component: authentication.required(lazy(() => import('views/Settings')))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //Smarth Bajaj Changes
  {
    path: '/about',
    component: authentication.required(BaseLayoutWithNavbar),
    routes: [
      {
        path: '/about',
        exact: true,
        component: authentication.required(lazy(() => import('views/About')))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/warning',
    component: BaseLayout,
    routes: [
      {
        path: '/warning',
        exact: true,
        component: lazy(() => import('views/Warning'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/',
    component: BaseLayout,
    routes: [
      {
        path: '/',
        exact: true,
        component: lazy(() => import('views/Landing'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
