import { AppThunk } from 'store';
import axios from 'common/utils/axios';
import authentication from '@kdpw/msal-b2c-react';
import {
  fetchStory,
  fetchStrenghts,
  fetchAreas,
  fetchAdditionalInfo, //Hammad Tahir T252 081021 - Showing "Additional information" in Story
  startLoading,
  stopLoading
} from './storySlice';
import { FocusArea, AreaApiType } from 'types/other';
import { Strength, Story, AdditionalInfo } from 'types/story'; //Hammad Tahir T252 081021 - Showing "Additional information" in Story

//** ASYNC FUNCS */
export const fetchStoryData = (): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());
    const story = await callMyStoryReadApi();
    dispatch(
      fetchStory({
        story
      })
    );
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

export const fetchStrenghtsData = (): AppThunk => async dispatch => {
  try {
    const strengths = await callStrengthReadApi();
    dispatch(
      fetchStrenghts({
        strengths
      })
    );
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

export const fetchMyAreas = (): AppThunk => async dispatch => {
  try {
    const focusAreas = await callFocusAreaListApi();
    const myFocusAreas = focusAreas.filter(item => item.isSelected);

    dispatch(
      fetchAreas({
        focusAreas: myFocusAreas
      })
    );
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

//Hammad Tahir T252 081021 - Showing "Additional information" in Story
export const fetchAdditionalInfoData = (): AppThunk => async dispatch => {
  try {
    const additionalInfo = await callAdditionalInfoReadApi();
    dispatch(
      fetchAdditionalInfo({
        additionalInfo
      })
    );
  } catch (err) {
    // dispatch(failed(err.toString()));
  }
};

export const sendMyStoryAccessRequest = (): AppThunk => async dispatch => {
  try {
    await callMyStoryCarerRequestAccessApi();
  } catch (err) {
    dispatch(stopLoading());
    // dispatch(failed(err.toString()));
  }
};

//** API FUNCS */
const callMyStoryReadApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  return axios
    .get(`/MyStory/Read/${sessionStorage.getItem('UserId')!}`)
    .then(response => {
      const story: Story = response.data;
      return story;
    });
};

const callStrengthReadApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  const values: Strength[] = [];
  return axios
    .get(`/Strength/Read/${sessionStorage.getItem('UserId')!}`)
    .then(response => {
      response.data['Items'].forEach((item: string, index: string) => {
        values.push({
          id: index,
          name: item
        });
      });
      return values;
    });
};

//Hammad Tahir T252 081021 - Showing "Additional information" in Story
const callAdditionalInfoReadApi = () => {
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + authentication.getAccessToken();
  const values: AdditionalInfo[] = [];
  return axios
    .get(`/AdditionalInfo/Read/${sessionStorage.getItem('UserId')!}`)
    .then(response => {
      response.data['Items'].forEach((item: string, index: string) => {
        values.push({
          id: index,
          name: item
        });
      });
      return values;
    });
};

export const callFocusAreaListApi = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
    const info = [
      {
        id: 'b1608260-c632-eb11-a813-000d3acae853',
        name: 'Mental health',
        image: 'mental-health.svg',
        color: '#F9FF83'
      },
      {
        id: 'a0fb116d-c632-eb11-a813-000d3acae853',
        name: 'Physical health / Self-care',
        image: 'physical-health.svg',
        color: '#B5EAE7'
      },
      {
        id: 'f8a09048-c632-eb11-a813-000d3acae853',
        name: 'Identity/ Self-esteem',
        image: 'self-esteem.svg',
        color: '#FEC6FF'
      },
      {
        id: 'fb769179-c632-eb11-a813-000d3acae853',
        name: 'Relationships',
        image: 'relationships.svg',
        color: '#FFCCCC'
      },
      {
        id: 'b11c8a5a-c632-eb11-a813-000d3acae853',
        name: 'Living skills',
        image: 'living-skills.svg',
        color: '#B3CBFF'
      },
      {
        id: '9099ee92-c632-eb11-a813-000d3acae853',
        name: 'Social networks',
        image: 'social-networks.svg',
        color: '#B5EAE7'
      },
      {
        id: '4bf6e39e-c632-eb11-a813-000d3acae853',
        name: 'Work / Education',
        image: 'work-education.svg',
        color: '#E0E0E0'
      },
      {
        id: 'b4352f5f-c532-eb11-a813-000d3acae853',
        name: 'Addictive behaviours',
        image: 'addictive-behaviours.svg',
        color: '#66A7BC'
      },
      {
        id: '6c001a4c-c532-eb11-a813-000d3acae853',
        name: 'Accommodation',
        image: 'accommodation.svg',
        color: '#EAE087'
      },
      {
        id: '88d16e86-c632-eb11-a813-000d3acae853',
        name: 'Responsibilities',
        image: 'responsibilities.svg',
        color: '#FFEE4D'
      },
      {
        id: '5aa64c3c-c632-eb11-a813-000d3acae853',
        name: 'Hope / Trust',
        image: 'hope-trust.svg',
        color: '#C4C4C4'
      }
    ];

    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + authentication.getAccessToken();
    return axios
      .get(`/FocusArea/List/${sessionStorage.getItem('UserId')}`)
      .then(response => {
        const focusAreas: FocusArea[] = [];
        response.data.forEach((area: AreaApiType) => {
          const areaObject: FocusArea = {
            id: area.Id,
            name: area.Label,
            color: info.find(item => item.id === area.Id)?.color!,
            image: info.find(item => item.id === area.Id)?.image!,
            description: area.Description,
            isSelected: area.IsSelected
          };
          focusAreas.push(areaObject);
        });

        sessionStorage.setItem('focusAreas', JSON.stringify(focusAreas));
        return focusAreas;
      });
  }
  else {
    const info = [
      {
        id: '088433ef-caec-e911-a812-000d3a79722d',
        name: 'Mental health',
        image: 'mental-health.svg',
        color: '#F9FF83'
      },
      {
        id: '12ef08fc-caec-e911-a812-000d3a79722d',
        name: 'Physical health / Self-care',
        image: 'physical-health.svg',
        color: '#B5EAE7'
      },
      {
        id: '157c4d02-cbec-e911-a812-000d3a79722d',
        name: 'Identity/ Self-esteem',
        image: 'self-esteem.svg',
        color: '#FEC6FF'
      },
      {
        id: 'd2588108-cbec-e911-a812-000d3a79722d',
        name: 'Relationships',
        image: 'relationships.svg',
        color: '#FFCCCC'
      },
      {
        id: '03598108-cbec-e911-a812-000d3a79722d',
        name: 'Living skills',
        image: 'living-skills.svg',
        color: '#B3CBFF'
      },
      {
        id: '7226830e-cbec-e911-a812-000d3a79722d',
        name: 'Social networks',
        image: 'social-networks.svg',
        color: '#B5EAE7'
      },
      {
        id: '09b88714-cbec-e911-a812-000d3a79722d',
        name: 'Work / Education',
        image: 'work-education.svg',
        color: '#E0E0E0'
      },
      {
        id: '28b88714-cbec-e911-a812-000d3a79722d',
        name: 'Addictive behaviours',
        image: 'addictive-behaviours.svg',
        color: '#66A7BC'
      },
      {
        id: 'b071951a-cbec-e911-a812-000d3a79722d',
        name: 'Accommodation',
        image: 'accommodation.svg',
        color: '#EAE087'
      },
      {
        id: '1680bf20-cbec-e911-a812-000d3a79722d',
        name: 'Responsibilities',
        image: 'responsibilities.svg',
        color: '#FFEE4D'
      },
      {
        id: '3a80bf20-cbec-e911-a812-000d3a79722d',
        name: 'Hope / Trust',
        image: 'hope-trust.svg',
        color: '#C4C4C4'
      }
    ];

    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + authentication.getAccessToken();
    return axios
      .get(`/FocusArea/List/${sessionStorage.getItem('UserId')}`)
      .then(response => {
        const focusAreas: FocusArea[] = [];
        response.data.forEach((area: AreaApiType) => {
          const areaObject: FocusArea = {
            id: area.Id,
            name: area.Label,
            color: info.find(item => item.id === area.Id)?.color!,
            image: info.find(item => item.id === area.Id)?.image!,
            description: area.Description,
            isSelected: area.IsSelected
          };
          focusAreas.push(areaObject);
        });

        sessionStorage.setItem('focusAreas', JSON.stringify(focusAreas));
        return focusAreas;
      });
  }
};

const callMyStoryCarerRequestAccessApi = () => {
  return axios.post(
    `/MyStoryShare/Carer/RequestAccess/${sessionStorage.getItem(
      'RecoveryPlanId'
    )}`
  );
};
