import React, { useState, useEffect } from 'react';

import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Hidden } from '@material-ui/core';

import { Button } from 'common/components';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#75B7FF',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  text: {
    fontFamily: 'Scada',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#FFFFFF'
  },
  subContainer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginBottom: '70px'
    }
  },
  buttonContainer: {
    width: '100%',
    padding: '0 25%',
    marginTop: '30px'
  },
  imageContainer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      height: '280px'
    },
    [theme.breakpoints.up('md')]: {
      height: '0',
      alignItems: 'flex-start'
    }
  },
  image: {
    position: 'absolute',
    right: '0'
  },
  image2: {
    position: 'relative',
    right: '0'
  },
  loaderContainer: {
    padding: '10px 0'
  },
  circulerProgress: {
    color: '#FFFFFF'
  }
}));

const Loader: React.FC = () => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    function tick() {
      // reset when reaching 100%
      setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
    }

    const timer = setInterval(tick, 10);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <div className={classes.loaderContainer}>
          <CircularProgress
            variant="determinate"
            value={progress}
            className={classes.circulerProgress}
          />
        </div>
        <span className={classes.text}>Sending your request...</span>
        <Hidden smDown>
          <div className={classes.buttonContainer}>
            <Button type="cancelLoader">Cancel</Button>
          </div>
        </Hidden>
      </div>
      <div className={classes.imageContainer}>
        <Hidden smUp>
          <img
            src="/images/loader/postman.png"
            alt=""
            className={classes.image}
          />
        </Hidden>
        <Hidden xsDown>
          <img
            src="/images/loader/postman_tablet.png"
            alt=""
            className={classes.image2}
          />
        </Hidden>
      </div>
      <Hidden mdUp>
        <div className={classes.subContainer}>
          <div className={classes.buttonContainer}>
            <Button type="cancelLoader">Cancel</Button>
          </div>
        </div>
      </Hidden>
    </div>
  );
};

export default Loader;
