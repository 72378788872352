/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

export default [
  {
    title: '',
    pages: [
      {
        id: '1',
        title: 'My Goals',
        href: '/goals/current',
        icon: '/images/navbar/home.png',
        iconActive: '/images/navbar/home_active.png'
      },
      {
        id: '2',
        title: 'Journey',
        href: '/journeys/summary',
        icon: '/images/navbar/journey.png',
        iconActive: '/images/navbar/journey_active.png'
      },
      {
        id: '3',
        title: 'My Story',
        href: '/story',
        icon: '/images/navbar/story.png',
        iconActive: '/images/navbar/story_active.png'
      },
      {
        id: '4',
        title: 'Safety Plan',
        href: '/safety',
        icon: '/images/navbar/safety.png',
        iconActive: '/images/navbar/safety_active.png'
      },
      {
        id: '5',
        title: 'Network',
        href: '/networks/people',
        icon: '/images/navbar/network.png',
        iconActive: '/images/navbar/network_active.png'
      }
    ]
  }
];
