import React, { useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  IconButton,
  Toolbar,
  Hidden,
  Typography,
  Dialog,
  DialogContent,
  Slide,
  Theme
} from '@material-ui/core';
import { Menu, Add } from '@material-ui/icons';
import { TransitionProps } from '@material-ui/core/transitions/transition';

import InvitePeople from './InvitePeople';
import EnterCode from './EnterCode';
import AddPeople from './AddPeople';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#175BA5'
  },
  textColor: {
    color: '#FFFFFF'
  },
  menuIcon: {
    color: '#FFFFFF',
    fontSize: '30px'
  },
  invitePeople: {
    [theme.breakpoints.up('xs')]: {
      bottom: '0',
      right: '0',
      width: '100%',
      height: '100%',
      position: 'fixed',
      background: '#FFFFFF',
      padding: '0'
    },
    [theme.breakpoints.up('sm')]: {
      width: '500px',
      height: '600px',
      position: 'relative',
      background: '#FFFFFF',
      padding: '0'
    }
  },
  addPeople: {
    [theme.breakpoints.up('xs')]: {
      bottom: '0',
      right: '0',
      width: '100%',
      position: 'fixed',
      background: '#FFFFFF',
      borderRadius: '12px 12px 0px 0px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px 30px',
      width: '400px',
      height: '290px',
      position: 'relative',
      background: '#FFFFFF',
      borderRadius: '12px 12px 0px 0px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px 30px',
      width: '400px',
      height: '290px',
      position: 'relative',
      background: '#FFFFFF',
      borderRadius: '12px 12px 0px 0px'
    }
  },
  enterCode: {
    [theme.breakpoints.up('xs')]: {
      bottom: '0',
      right: '0',
      width: '100%',
      position: 'fixed',
      background: '#FFFFFF',
      borderRadius: '12px 12px 0px 0px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
      width: '400px',
      height: '240px',
      position: 'relative',
      background: '#FFFFFF',
      borderRadius: '12px 12px 0px 0px'
    }
  }
}));

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

type Props = {
  className: string;
  onOpenNavBarMobile: () => void;
};

const TopBar: React.FC<Props> = ({ onOpenNavBarMobile, className }) => {
  const classes = useStyles();

  /** add people */
  const [open3, setOpen3] = useState(false);
  const handleClose3: () => void = () => {
    setOpen3(false);
  };
  const handleClickOpen3: () => void = () => {
    setOpen3(true);
  };

  /** Invite people */
  const [open2, setOpen2] = useState(false);

  const handleClose2: () => void = () => {
    setOpen2(false);
  };
  const handleClickOpen2: () => void = () => {
    handleClose3();
    setOpen2(true);
  };

  /** enter code */
  const [open4, setOpen4] = useState(false);
  const handleClose4: () => void = () => {
    setOpen4(false);
  };
  const handleClickOpen4: () => void = () => {
    handleClose3();
    setOpen4(true);
  };

  const invitePeopleDialog = (
    <Dialog
      open={open2}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose2}>
      <DialogContent className={classes.invitePeople}>
        <InvitePeople close={handleClose2} />
      </DialogContent>
    </Dialog>
  );

  const addPeopleDialog = (
    <Dialog
      open={open3}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose3}>
      <DialogContent className={classes.addPeople}>
        <AddPeople
          close={handleClose3}
          openEnterCode={handleClickOpen4}
          openInvitePeople={handleClickOpen2}
        />
      </DialogContent>
    </Dialog>
  );

  const enterCodeDialog = (
    <Dialog
      open={open4}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose4}>
      <DialogContent className={classes.enterCode}>
        <EnterCode close={handleClose4} />
      </DialogContent>
    </Dialog>
  );

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onOpenNavBarMobile}>
              <Menu className={classes.menuIcon} />
            </IconButton>
          </Hidden>
        </div>
        <div style={{ flexGrow: 2 }}>
          <Typography variant="h1" className={classes.textColor}>
            My People
          </Typography>
        </div>

        <IconButton onClick={handleClickOpen3}>
          <Add fontSize="large" style={{ fill: '#FFFFFF' }} />
        </IconButton>
      </Toolbar>
      {open2 && invitePeopleDialog}
      {open3 && addPeopleDialog}
      {open4 && enterCodeDialog}
    </AppBar>
  );
};

export default TopBar;
